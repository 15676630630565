const p= {
    addXAxis: Symbol("addXAxis"),
    addYAxis: Symbol("addYAxis"),
    updateXAxis:Symbol("updateXAxis"),
    updateYAxis:Symbol("updateYAxis"),
    updateAnnotations:Symbol("updateAnnotations"),
    updateCartoons:Symbol("updateCartoons"),
    updateBranches:Symbol("updateBranches"),
    updateNodeBackgrounds:Symbol("updateNodeBackgrounds"),
    updateNodes:Symbol("updateNodes"),
    updateBranchStyles:Symbol("updateBranchStyles"),
    updateNodeStyles:Symbol("updateNodeStyles"),
    updateNodeBackgroundStyles:Symbol("updateNodeBackgroundStyles"),
    updateCartoonStyles:Symbol("updateCartoonStyles"),
    branchPathGenerator:Symbol("branchPathGenerator"),
    pointToPoint:Symbol("pointToPoint"),
    setUpScales:Symbol("setUpScales"),
    svg:Symbol("SVG"),
    tree:Symbol("tree"),
    layout:Symbol("layout"),
    vertices:Symbol("vertices"),
    edges:Symbol("edges"),
    baubleMap:Symbol("baubleMap"),
    branchMap:Symbol("branchMap"),
    updateVerticesAndEdges:Symbol("updateVerticesAndEdges"),
    node:Symbol("node"),
    branch:Symbol("branch"),
    nodeBackground:Symbol("node background"),
    vertexFactory:Symbol("vertexFactory"),
    edgeFactory:Symbol("edgeFactory"),
    backgroundNodesFactory:Symbol("backgroundNodesFactory"),
    updateBackgroundNodes:Symbol("updateBackgroundNodes"),
    axis:Symbol("axis"),
    legend:Symbol("legend")

};
export default p;